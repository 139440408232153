import React from 'react'
import NativeView from '../native-view'

export default function Sizer({
  children,
  ...sx
}: React.ComponentProps<typeof NativeView>['sx'] & {
  children?: React.ReactNode
}) {
  return (
    <NativeView pointerEvents={(sx as any)?.pointerEvents} sx={sx}>
      {children}
    </NativeView>
  )
}
